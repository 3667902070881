import { useEffect, useRef } from "react";

function App() {

	const heroIndex = useRef(0);
	const bannerRef = useRef(null);
	const dotsRef = useRef(null);
	useEffect(()=>{
		if ( dotsRef.current && bannerRef.current){
			const timer = setInterval(() => {				
				
				const slides = bannerRef.current.querySelectorAll('.slide');
				const dots = dotsRef.current.querySelectorAll('*');
				if ( ++heroIndex.current >= slides.length ) heroIndex.current = 0;

				for(let [index, item] of slides.entries()){
					const state = (index === heroIndex.current) ? 'active' : '';
					item.className = 'slide ' + state;
					dots[index].className = state;
				}

			}, 4000);
			return ()=>timer;
		}
	},[dotsRef]);


	return (
		<div className="flex-stretch" style={{position: 'relative'}}>
			<div className="header flex-col justify-content-between">
				<div className="contact-container">
					<a href="mailto:producties@nenadekker.nl"><p className="menu-item link">producties@nenadekker.nl</p></a>
					<a href="tel:+31636593434"><p className="menu-item link">06.36593434</p></a>
				</div>
				<div className="logo-container flex-row justify-content-end">
					<h1>Nena</h1>
					<h1>Dekker</h1>
				</div>
			</div>
			<div ref={bannerRef} className="banner flex-col justify-content-end">
				<div className="slide active"></div>
				<div className="slide"></div>
				<div className="slide"></div>
				<div className="slide"></div>
				<div ref={dotsRef} className="dots container-v-22">
					<div className="active"></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>			
			<div className="about-container">
				<div className="head-container">
					<div className="head slide-in"></div>
				</div>
				<div className="info-container">
					<p className="menu-item" style={{marginLeft: 0, fontSize: '1.6em', marginBottom: -22}}>over nena</p>
                    <p style={{marginBottom: 22}}>Freelance Event Manager</p>
					<p style={{marginBottom: 22}}>In 2022 heb ik stage mogen lopen in het bruisende Forum Groningen. Dit heb ik mogen doen in het productieteam, waar ik een half jaar lang mocht meedraaien met de tofste producties. Van IFFR & IDFA tot aan driedaagse congressen en de ESNS Conferentie.</p>
					<p>In mijn laatste fase van mijn stage heb ik de conferentie van ESNS 2023 vanuit het Forum Groningen mogen produceren. Hierbij was ik van begin tot eind verantwoordelijk voor de productie. Als klap op de vuurpijl mochten we Dua Lipa ontvangen in de prachtige Rabo Studio. Een erg leerzaam en ontzettend uitdagend project!</p>
				</div>
			</div>
			
			<div className="menu-item cw">2023 © Ethan Hermsey</div>			
		</div>
	);
}

export default App;